import React from 'react';
import {Button, TryAmigoContainer} from "./styles";
import PropTypes from 'prop-types';
import LazyImage from '../common/LazyImage/LazyImage';
import { ImageUrl } from '../../utils/constants';

const TryAmigo = (props) => (
  <TryAmigoContainer>
    <picture>
      <source srcSet={ImageUrl + "/images/AboutPageV2/amigo_iphone.png"} type="image/png"/>
      <LazyImage src={ImageUrl + "/images/AboutPageV2/amigo_iphone.png"}/>
    </picture>
    <div>
      <h1>Try Woliba For Free</h1>
      <p>Download the Woliba app or sign up online to start Healthy life today.</p>
      <Button onClick={()=>props.history.push('/contact')}>BOOK A DEMO</Button>
    </div>
  </TryAmigoContainer>
);

TryAmigo.propTypes = {
  history: PropTypes.object.isRequired,
};

export default TryAmigo;