import styled from 'styled-components';
import { ImageUrl } from '../../utils/constants';
const BoldFont = 'Rubik-Medium';
const RegularFont = 'Rubik-Light';
const MediumFont = 'Rubik-Regular';
const backgroundUrl = `url(${ImageUrl}/images/HomePageV2/tailored-background.png)`;

const Section = styled.div`
  float: left;
  width: 100%;
  background-image: linear-gradient(#05344D,#136680);
  padding: 80px 90px 70px 98px;
  display: flex;
  align-item: center;
  justify-content: center;
  > div {
    float: left;
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-right: 50px;
  }
  > div:first-child{
    width: 55%;
    > picture {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    img {
        width: 85%;
    }
  }
  > div:last-child {
      display: block;
     align-items: flex-start;
  }
  @media (max-width: 1000px) {
    padding: 60px 6vw;
    flex-direction: column;
    > div {
        width: 100%;
        padding-right: 0px;
        align-items: flex-start;
    }
    > div:first-child{
        width: 100%;
        margin-bottom: 20px;
        align-items: center;
        img {
            width: 80%;
        }
    }
  }
  
  @media (max-width: 500px) {
    padding: 50px 10px;
  }
  h1 {
     margin-bottom: 15px;
     font-size: 32px;
     color: #fff;
     font-family: ${BoldFont};
     letter-spacing: 0;
     margin-top: 15px;
     font-weight: 500;
     line-height: 38px;
     max-width: 410px;
  }
  p {
    font-size: 18px;
    line-height: 26px;
    color: #fff;
    font-family: ${RegularFont};
    letter-spacing: 0;
    font-weight: 400;
  }
  @media (min-width: 1600px) {
    padding-left: calc(calc(100% - 1404px) / 2);
    padding-right: calc(calc(100% - 1420px) / 2);
  }
  @media (max-width: 1200px) {
    h1 {
      max-width: unset;
    }
    > div:last-child {
      padding-right: 40px;
    }
  }
`;

const PromotionalContainer = styled.div`
  width: 100%;
  background-image: linear-gradient(#E6F6FF, #CFEDFF);
  float: left;
  padding: 10px 90px 10px 98px;
  
 > div {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
      span, div {
            margin: 15px;
            font-size: 16px;
            letter-spacing: 1.4px;
            color: #1e577a;
            font-family: ${BoldFont};
            text-align: center;
            padding: 15px 0px;
            font-weight: 700;
      }
  }
  @media (min-width: 1600px) {
    padding-left: calc(calc(100% - 1404px) / 2);
    padding-right: calc(calc(100% - 1420px) / 2);
  }
`;

const LogoContainer = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height ? props.height : 'auto'};
  img {
    width: 100%;
    height: 100%;
  }
`;

const CenterContainer = styled.div`
  float: left;
  width: 100%;
  background-color: #F6F6F8;
  @media (min-width: 1600px) {
    padding-left: calc(calc(100% - 1600px) / 2);
    padding-right: calc(calc(100% - 1600px) / 2);
  }
`;

const CoreValueContainer = styled.div`
  float: left;
  width: 100%;
  padding: 60px 0px 80px 150px;
  > div {
    width: 35%;
    float: left;
    ul {
      list-style-type: none;
      padding: 0px;
      float: left;
      picture, img {
        width: 25px;
        position: relative;
        float: left;
        top: 2px;
      }
      li {
        position: relative;
        font-size: 22px;
        font-family: ${RegularFont};
        position: relative;
        float: left;
        display: flex;
        align-items: flex-start;
        width: 60%;
        clear: both;
        padding-top: 60px;
        @media (max-width: 1201px){
            width: 80%;
        }
        @media (max-width: 750px){
            width: 100%;
            padding-bottom: 25px;
        }
        span {
          float: left;
          padding-left: 10px;
        }
      }
    }
  }
  >div:last-child {
    width: 65%;
    padding-top: 50px;
    img {
      float: right;
      width: 100%;
    }
  }  
  @media (max-width: 1000px){
    padding: 35px 0px 35px 6vw;
  }
  @media (max-width: 650px){
    > div {
        width: 100%;
        h1 {
          margin-bottom: 30px
        }
    }
    >div:last-child {
        width: 100%;
        padding-top: 10px;
    }
  }
  @media (max-width: 650px) {
    padding: 35px 0px 35px 10px;
  }
`;

const LeadershipTeamSection = styled.div`
  float: left;
  width: 100%;
  display: none;
  padding: ${(props) => props.advisors ? '10px 90px 50px 98px': '60px 90px 50px 98px'};
  h1 {
    text-align: center
  }
  @media (max-width: 1000px) {
    padding: 50px 6vw;
  }
  @media (max-width: 500px) {
    padding: 50px 10px;
  }
`;

const TeamCardContainer = styled.div`
  float: left;
  width: 100%;
  padding-top: 50px;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
    > div {
      min-height: 395px;
    }
`;

const LeadershipCard = styled.div`
  float: left;
  background-color: #fff;
  max-width: 300px;
  min-width: 250px;
  width: 24%;
  margin: 20px 5px 0px 5px;
  text-align: center;
  padding: 20px 0px 0px 0px;
  cursor: pointer;
  :hover {
    box-shadow: 7px 7px 0px #D1D1D3;
  }
  :hover .readMore{
    display: flex;
  }
  img {
    width: 135px;
    border-radius: 50%;
    height: 135px;
    object-fit: cover;
  }
  > div {
    padding-top: 40px;
    width: 120px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    i {
      font-size: 28px;
      color: #A2A2A2;
    }
  }
  > span {
    font-size: 18px;
    color: #A2A2A2;
    padding-top: 20px;
    padding-right: 5px;
    display: inline-block;
    font-family: ${MediumFont};
    font-weight: 500;
  }
  > p {
    font-size: 18px;
    color: #2b281e;
    font-family: ${MediumFont};
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 500;
  }
  .readMore{
    background-color: #1e76ab;
    width: 100%;
    height: 50px;
    display: none;
    padding:0px 10px;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    margin-top: -15px;
    i {
      color: #fff;
    }
  } 
`;

const TestimonialContainer = styled.div`
  float: left;
  width: 100%;
  background-color: #fff;  
  padding-bottom: 60px;
  img { 
    margin-top: 25px;
  }
  p {
    margin-top: 15px;
    font-family: Rubik-Light;
    font-weight: 400;
  }
  h4 {
    color: #2b281e;
    font-family: Rubik-Regular;
    margin-top: 0;
  }
  @media (max-width: 1120px){
    padding-bottom: 0px;
  }
  @media (max-width: 785px){
    padding-bottom: 60px;
  }
  @media (min-width: 1600px) {
    padding-left: calc(calc(100% - 1600px) / 2);
    padding-right: calc(calc(100% - 1600px) / 2);
  }
`;

const TryAmigoContainer = styled.div`
  float: left;
  width: 100%;
  height: 600px;
  ${'' /* background: url(../../images/HomePageV2/tailored-background.png); */}
  background: ${backgroundUrl};
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 420px;
  }
  > div {
      max-width: 380px;
      margin-left: 15%;
      h1 {
        font-size: 52px;
        color: #fff;
        font-family: ${BoldFont};
        margin-bottom: 20px;
        font-weight: 500;
     }
     p {
        font-size: 15px;
        color: #fff;
        font-family: ${RegularFont}
        line-height: 20px;
        font-weight: 400;
     }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    padding: 40px 6vw;
    img {
      height: 320px;
    }
    > div {
      max-width: unset;
      width: 100%
      margin: 0
    }
  }
  @media (min-width: 1600px) {
    padding-left: calc(calc(100% - 1600px) / 2);
    padding-right: calc(calc(100% - 1600px) / 2);
  }
`;

const Button = styled.button`
    margin-top: 40px;
    width: 180px;
    background-color: #fff;
    color: #1e76ab;
    padding: 13px 10px;
    border-radius: 40px;
    border: none;
    font-size: 14px;
    font-family: ${BoldFont};
    &:active, &:focus {
      outline: none;
    }
`;

const Layout = styled.div`
  float: left;
  overflow: hidden;
  font-family: ${RegularFont};
  min-height: 100vh;
  width: 100%;
`;

const Title = styled.h1`
  font-size: 32px;
  color: ${(props) => props.color ? props.color : '#1e76ab'};
  font-family: ${BoldFont};
  margin-top: 0;
  margin-bottom: 15px;
  font-weight: 500;
`;

const MissionContainer = styled.div`
  float: left;
  width: 100%;
  text-align: center;
  padding: 80px 0px 60px 0px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  > p {
    text-align: center;
    width: 58%;
    margin-top: 15px;
    font-family: ${RegularFont};
    font-size: 22px;
    color: #2b281e;
    font-weight: 500;
    @media (max-width: 600px) {
      width: 80%;
    }
  }
`;

const GetButton = styled.button`
  height: 50px;
  background-color: transparent;
  font-size: 14px;
  color: #fff;
  padding: 0 2vw;
  border: 2px solid #fff;
  border-radius: 4px;
  margin-left: 15px;
  cursor: ${({disabled}) => disabled ? 'not-allowed !important' : 'pointer'};
  &:active, &:focus {
    outline: none;
  }
  i {
    padding-left: 10px;
    padding-top: 5px;
  }
`;

const ContactUsSection = styled.div`
  float: left;
  width: 100%;
  background-color: #091C27;
  padding: 20px 90px 20px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    margin-left: 15px;
  }
  p {
    font-size: 18px;
    color: #fff;
    font-family: ${RegularFont};
    letter-spacing: 0;
    font-weight: 400;
    margin: 0px;
  }
  input {
    height: 50px;
    background-color: #fff;
    font-size: 14px;
    color: #000;
    border: none;
    width: 250px;
    padding: 0 1vw;
    border-radius: 4px;
    float: left;
      &:active, &:focus {
        outline: none;
      }
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    p {
      margin-bottom: 15px;
    }
  }
  
  @media (max-width: 600px) {
    padding: 20px 6vw;
    p {
      text-align: center;
    }
    > div {
      width: 100%;
    }
    form {
      display: flex;
      flex-direction: column;
      align-items: center;
        input {
          width: 80%;
        }
        button {
          margin-left: 0px;
          margin-top: 15px;
          width: 80%;
        }
    }
  }
`;

export {
  Layout,
  Title,
  MissionContainer,
  Section,
  PromotionalContainer,
  LogoContainer,
  CenterContainer,
  CoreValueContainer,
  LeadershipTeamSection,
  TeamCardContainer,
  LeadershipCard,
  TestimonialContainer,
  TryAmigoContainer,
  Button,
  GetButton,
  ContactUsSection
};
