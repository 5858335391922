/* eslint-disable no-undef */
import React, { Component, Suspense } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
const LandingFooterPanelV2 = React.lazy(() => import('../LandingFooterPanelV3/index'))
const MemberTile = React.lazy(() => import('./MemberTile'))
const Testimonials = React.lazy(() => import('../MainDashboardV2/Testimonials'))
const ContactUs = React.lazy(() => import('./ContactUs'))
const TryAmigo = React.lazy(() => import('./TryAmigo'))

import { Layout, Title, MissionContainer, Section, PromotionalContainer, LogoContainer, CenterContainer, CoreValueContainer,
  LeadershipTeamSection, TeamCardContainer, TestimonialContainer} from './styles';

import { checkPermission } from '../../utils/methods';
import { saveContactUsInfo } from '../../redux/actions';
import LazyImage from '../common/LazyImage/LazyImage';
import { ImageUrl } from '../../utils/constants';
import Waiting from '../Waiting';

const leaderShipTeam = [{
  name: 'Chris Thomas',
  position: 'CFO',
  url: ImageUrl + '/images/AboutPageV2/leader_1.png'
}, {
  name: 'Ryan Farris',
  position: 'Lead Engineering',
  url: ImageUrl + '/images/AboutPageV2/leader_2.png'
}, {
  name: 'Nathan Figg',
  position: 'CEO',
  url: ImageUrl + '/images/AboutPageV2/leader_3.png'
}, {
  name: 'Citra Kirana',
  position: 'Sales Marketing',
  url: ImageUrl + '/images/AboutPageV2/leader_4.png'
}];

const advisorsTeam = [{
  name: 'Daniel Brad',
  position: 'CFO',
  url: ImageUrl + '/images/AboutPageV2/advisors_1.png'
}, {
  name: 'Polly Chloe',
  position: 'Lead Engineering',
  url: ImageUrl + '/images/AboutPageV2/advisors_2.png'
}, {
  name: 'Marion Maud',
  position: 'CEO',
  url: ImageUrl + '/images/AboutPageV2/advisors_3.png'
}, {
  name: 'Suzan Felicity',
  position: 'Sales Marketing',
  url: ImageUrl + '/images/AboutPageV2/advisors_4.png'
}];

class AboutPage extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
    };
  }

  leaderShipTeamMember = (payload) => (
    <TeamCardContainer>
      {payload.map((item, index) => (
        <div key={index}>
          <Suspense fallback={<Waiting />}>
            <MemberTile key={index} item={item} />
          </Suspense>
        </div>
      ))}
    </TeamCardContainer>
  );

  render() {
    const { history } = this.props;
    const authToken =
      checkPermission() && localStorage && localStorage.getItem("AUTH_TOKEN")
        ? true
        : false;
    return (
      <Layout>
        <Section>
          <div>
            <picture>
              <source
                srcSet="/public/images/AboutPageV2/Illustration-flying-man.png"
                type="image/png"
              />
              <LazyImage
                src={
                  ImageUrl + "/images/AboutPageV2/Illustration-flying-man.png"
                }
              />
            </picture>
          </div>
          <div>
            <h1>The Trailblazers of Wellness and Engagement</h1>
            <p>
              Our platform is easy, measurable, social, rewarding and downright
              effective. We’ve designed Woliba to not only boost company
              efficiency and culture, but we’ve also designed it to hold
              ourselves accountable. You can monitor the data by the minute and
              ensure you’re getting the results you pay for. Full transparency
            </p>
          </div>
        </Section>
        <PromotionalContainer>
          <div>
            <span>FEATURED IN</span>
            <LogoContainer width="90px">
              <picture>
                <source srcSet={ImageUrl + "/images/AboutPageV2/forbes.png"} />
                <LazyImage src={ImageUrl + "/images/AboutPageV2/forbes.png"} />
              </picture>
            </LogoContainer>
            <LogoContainer width="85px">
              <picture>
                <source
                  srcSet={ImageUrl + "/images/AboutPageV2/tech-group.png"}
                />
                <LazyImage
                  src={ImageUrl + "/images/AboutPageV2/tech-group.png"}
                />
              </picture>
            </LogoContainer>
            <LogoContainer width="135px">
              <picture>
                <source srcSet="/public/images/AboutPageV2/man-health.png" />
                <LazyImage
                  src={ImageUrl + "/images/AboutPageV2/man-health.png"}
                />
              </picture>
            </LogoContainer>
            <LogoContainer width="90px">
              <picture>
                <source srcSet="/public/images/AboutPageV2/business-insider.png" />
                <LazyImage
                  src={ImageUrl + "/images/AboutPageV2/business-insider.png"}
                />
              </picture>
            </LogoContainer>
          </div>
        </PromotionalContainer>
        <CenterContainer>
          <MissionContainer>
            <Title>Our Mission</Title>
            <p>
              Woliba strives to grow company culture from the ground up with a
              holistic approach to wellbeing. We make it incredibly easy to
              implement and manage so that retention and participation stay
              high.
            </p>
          </MissionContainer>
          <CoreValueContainer>
            <div>
              <Title color="#2b281e">Our Core Values</Title>
              <ul>
                <li>
                  <picture>
                    <source
                      srcSet="/public/images/AboutPageV2/star-icon.png"
                      type="image/png"
                    />
                    <LazyImage
                      src={ImageUrl + "/images/AboutPageV2/star-icon.png"}
                    />
                  </picture>
                  <span>Treat Everybody Like a Buddy</span>
                </li>
                <li>
                  <picture>
                    <source
                      srcSet="/public/images/AboutPageV2/star-icon.png"
                      type="image/png"
                    />
                    <LazyImage
                      src={ImageUrl + "/images/AboutPageV2/star-icon.png"}
                    />
                  </picture>
                  <span>Mindful of Health and Wellbeing</span>
                </li>
                <li>
                  <picture>
                    <source
                      srcSet="/public/images/AboutPageV2/star-icon.png"
                      type="image/png"
                    />
                    <LazyImage
                      src={ImageUrl + "/images/AboutPageV2/star-icon.png"}
                    />
                  </picture>
                  <span>Be Passionate and Determined</span>
                </li>
                <li>
                  <picture>
                    <source
                      srcSet="/public/images/AboutPageV2/star-icon.png"
                      type="image/png"
                    />
                    <LazyImage
                      src={ImageUrl + "/images/AboutPageV2/star-icon.png"}
                    />
                  </picture>
                  <span>Be Adventurous, Creative, and Open-minded</span>
                </li>
                <li>
                  <picture>
                    <source
                      srcSet="/public/images/AboutPageV2/star-icon.png"
                      type="image/png"
                    />
                    <LazyImage
                      src={ImageUrl + "/images/AboutPageV2/star-icon.png"}
                    />
                  </picture>
                  <span>Pursue Growth and Learning</span>
                </li>
                <li>
                  <picture>
                    <source
                      srcSet="/public/images/AboutPageV2/star-icon.png"
                      type="image/png"
                    />
                    <LazyImage
                      src={ImageUrl + "/images/AboutPageV2/star-icon.png"}
                    />
                  </picture>
                  <span>Do More with Less</span>
                </li>
              </ul>
            </div>
            <div>
              <picture>
                <source
                  srcSet="/public/images/AboutPageV2/core-values.png"
                  type="image/png"
                />
                <LazyImage
                  src={ImageUrl + "/images/AboutPageV2/core-values.png"}
                />
              </picture>
            </div>
          </CoreValueContainer>
          <LeadershipTeamSection>
            <Title color="#2b281e">Our Leadership Team</Title>
            {this.leaderShipTeamMember(leaderShipTeam)}
          </LeadershipTeamSection>
          <LeadershipTeamSection advisors>
            <Title color="#2b281e">Advisors</Title>
            {this.leaderShipTeamMember(advisorsTeam)}
          </LeadershipTeamSection>
        </CenterContainer>
        <TestimonialContainer>
          <Suspense fallback={<Waiting />}>
            <Testimonials
              title="ENHANCING EMPLOYEE EXPERIENCE"
              marginTop="60px"
            />
          </Suspense>
        </TestimonialContainer>
        <Suspense fallback={<Waiting />}>
          <TryAmigo history={history} />
        </Suspense>
        <Suspense fallback={<Waiting />}>
          <ContactUs history={history} />
        </Suspense>
        <Suspense fallback={<Waiting />}>
          <LandingFooterPanelV2 isUser={authToken} />
        </Suspense>
      </Layout>
    );
  }
}

AboutPage.propTypes = {
  history: PropTypes.object.isRequired,
  saveContactUsInfo: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) => ({
  saveContactUsInfo: (name, value) => dispatch(saveContactUsInfo(name, value))
});

export default withRouter(connect(null, mapDispatchToProps)(AboutPage));
